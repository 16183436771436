import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FaChevronRight } from "react-icons/fa"
import Helmet from "react-helmet"

const SitemapPage = ({ data, location }) => {
	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": "サイトマップ",
			"item": "https://hula-la.com"+location.pathname
		}]
	};

	return (
		<Layout>
			<div className="section" style={{padding: `0.5rem 1rem`}}>
				<div className="container">
					<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`}}>
						<ul>
							<li><Link to="/">トップ</Link></li>
							<li className="is-active"><Link to="" aria-current="page">サイトマップ</Link></li>
						</ul>
					</nav>
					<Helmet>
  	   			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
	   			</Helmet>
					<div className="container">
						<h2 className="title is-5" style={{marginBottom: `0.5rem`}}>サイトマップ</h2>
						<hr style={{margin: `0.5rem 0`}}/>
					</div>
					<div className="content">
						<div className="columns is-multiline is-gapless">
							{data.allSubbrandsYaml.edges.map(n => {
								const brand = data.allBrandsYaml.edges.find(nn => {
									return n.node.brand_id === nn.node.id
								})
								return (
									<>
										{n.node.types.map(m => {
											const type = data.allTypesYaml.edges.find(mm => {
												return m.type_id === mm.node.id 
											})
											return (
												<>
													{m.sizes.map(l => {
														const size = data.allSizesYaml.edges.find(ll => {
															return l.size_id === ll.node.id 
														})
														if(!brand) return <></>
														if(!type) return <></>
														if(!size) return <></>
														return (
															<div className="column is-half">
																<Link to={`/${brand.node.id}_${type.node.id}_${size.node.id}.html`}>
																	<div className="card">
																		<div className="card-content">
																			<div className="media">
																				<div className="media-left">
																				</div>
																				<div className="media-content">
																					<div className="content">
																						<p className="title is-6">{`${brand.node.description} ${type.node.description} ${size.node.description}`}</p>
																					</div>
																				</div>
																				<div className="media-right">
																					<span className="icon" style={{height: `2.5em`}}>
																						<FaChevronRight />
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</Link>
															</div>
														)		
													})}
												</>
											)
										})}
									</>
								)
							})}
						</div>
					</div>
				</div>
			</div>
  	  <Seo title="サイトマップ" canonical={location.pathname} />
		</Layout>			
  )
}

export const query = graphql`
{
	allBrandsYaml {
		edges {
			node {
				id
				description
			}
		}
	}
	allTypesYaml {
		edges {
			node {
				id
				description
			}
		}
	}
	allSizesYaml {
		edges {
			node {
				id
				description
			}
		}
	}
	allSubbrandsYaml {
		edges {
			node {
				brand_id
				subbrand_id
				description
				types {
					type_id 
					sizes {
						size_id
						min
						max
						unit
						num	
					}
				}
			}
		}
	}
}
`

export default SitemapPage
